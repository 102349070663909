import swal from "sweetalert2";
import DatePicker from "../validators/datepicker";
import validations from "../fieldValidations";
import common from "../utils/common";
import clickStream from "../utils/clickStream";
import configData from "../../config";
import actualpc9v from "../validators/actualPC9Validator";
import avv from "../validators/anticipatedVolumeValidator";
import fcv from "../validators/fabricCodeValidator";
import pc9v from "../validators/pc9Validator";
import pnv from "../validators/protoneedsValidator";
import snv from "../validators/sampleneedsValidator";
import cogsv from "../validators/targetCOGSValidator";
import AgGridThumbnail from '../components/AgGridThumbnail.vue'

async function beforeMount(vm) {
  const lookupTypes = configData.lookupTypes;
  
  await Promise.all([
    common.fetchLookupData(vm, [lookupTypes.schedules, lookupTypes.gtmStages, lookupTypes.subclasses])
  ]);
  
  vm.columnDefs = getColumnDefs(vm);
  common.postMountColumnDefSetup(vm);
}

function getColumnDefs(vm) {
  return [
    {
      headerName: "",
      field: "checkbox",
      pinned: "left",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      width: 45,
      cellClassRules: {
        required: params => params.node.isSelected() && gmValidation(params.data).length,
        recentRow: function(params) {
          var reqDate = new Date(params.data.modifiedDate);
          var today = new Date();
          today.setDate(today.getDate() - 5);
          if (reqDate > today) {
            return true;
          }
        },
      }
    },

    {
      headerName: "Pull Back / More Info",
      field: "approval_state",
      editable: params => params.data.lastAction !== "MORE_INFO",
      cellEditor: "agRichSelectCellEditor",
      suppressPaste: true,
      pinned: "left",
      width: 185,
      cellEditorParams: params => {
        const currState = parseInt(params.data.currentState);
        return (currState === 4) ? { values: ["","PULL_BACK"] } : { values: ["","MORE_INFO"] };
      },
      cellClassRules: {
        noneditableCol: params => params.data.lastAction === "MORE_INFO",
      }
    },

    {
      headerName: "Request ID",
      field: "id",
      filter: true,
      width: 120,
      pinned: "left",
      cellClass: "noneditableCol",
      comparator: function(valueA, valueB) {
        return valueA - valueB;
      }
    },
    // {
    //   headerName: "Parent ID",
    //   field: "parentId",
    //   cellClass: "noneditableCol",
    //   filter: true,
    //   pinned: "left",
    // },
    {
      headerName: "Status",
      field: "lastAction",
      cellClass: "noneditableCol",
      width: 170,
      pinned: "left",
      filter: "agSetColumnFilter",
      valueGetter: function(params) {
        const currentState = parseInt(params.data.currentState);
        const lastAction = params.data.lastAction;

        if (lastAction === 'MORE_INFO') return lastAction;
        if (currentState === 2) { {
          // this property is added post fetch, see common.js
          return (params.data.submittedToGML) ? 'Submitted to GML' : 'New';
        }
          // came from RPL or was more_info'd
          // return (params.data.globalMerchant === undefined || lastAction === 'MORE_INFO_RESPONSE')
          //   ? 'New'
          //   : 'Submitted to GML';
        }
        if (currentState === 3) return "Waiting for Data Review";
        if (currentState === 4) return 'Submitted to PD';  // this can be pulled back
        
        // there shouldn't be other states here
      },
      cellClassRules: {
        moreinfo: params => params.node.data.lastAction === "MORE_INFO"
      }
    },
    {
      headerName: "More Info Comments",
      field: "moreInfoComments",
      editable: params => params.data.lastAction === "MORE_INFO" || params.data.approval_state === "MORE_INFO",
      cellClassRules: {
        noneditableCol: params => !(params.data.lastAction === "MORE_INFO" || params.data.approval_state === "MORE_INFO")
      },
      cellEditorParams: {
        maxLength: 1000,
    },
      pinned: "left",
      cellEditor: "agLargeTextCellEditor"
    },
    {
      headerName: "Thumbnail",
      field: 'productUrl',
      pinned: "left",
      width:130,
      filter: false,
      cellRendererFramework: AgGridThumbnail,
    },
    {
      headerName: "Curr Stage",
      field: "currentState",
      cellClass: "noneditableCol",
      filter: true,
      width: 130,
      pinned: "left",
      hide: true,
    },
    {
      headerName: "Assign To Role",
      field: "toState",
      editable: params => params.data.approval_state === "MORE_INFO",
      width: 140,
      cellClassRules: {
        noneditableCol: params => params.data.approval_state !== "MORE_INFO",
        required: params => params.node.isSelected() && !FIELD_VALIDATION_MAP.toState(params.data),
      },
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: () => common.assignToRoleDropdownValues()
    },
    {
      headerName: "Season",
      field: "season",
      width: 120,
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: vm.seasonCodes
      },
      filterParams: { comparator: common.seasonComparator },
      cellClassRules: {
        required: row => row.node.isSelected() && !FIELD_VALIDATION_MAP.season(row.data),
      }
    },
    {
      headerName: "PC5/PC9",
      field: "similarPC9",
      filter: true,
      cellEditor: pc9v,
      cellStyle: { overflow: "visible !important" },
      editable: true,
    },
    {
      headerName: "GTM Stage",
      field: "gtmStage",
      filter: true,
      width: 145,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData[configData.lookupTypes.gtmStages])
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData[configData.lookupTypes.gtmStages], params.value);
      },
      suppressPaste: true
    },

    {
      headerName: "Brand",
      field: "brand",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: { values: common.getBrandDropdownList(vm) },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.brand, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.brand, params.value);
      },
    },
    
    {
      headerName: "Clusters",
      field: "requestingRegion",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: { values: configData.datafields.cluster },
    },
    
    {
      headerName: "Requesting Merchant",
      field: "requestingMerchant",
      editable: true,
      filter: true,
    },
    
    {
      headerName: "Reason Code",
      field: "requestReason",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: { values: configData.datafields["requestReasonCodes"] }
    },

    {
      headerName: "Channel",
      field: "channel",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: { values: configData.datafields["channels"] },
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.gender)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.gender, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.gender, params.value);
      },
    },
    
    {
      headerName: "Category",
      field: "category",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.category)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.category, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.category, params.value);
      },
    },
    
    {
      headerName: "Class & Subclass",
      field: "classSubClass",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: function(row) {
        var subClassValues = common.extractValues(vm.lookupData.classSubClass);
        var filteredSubClassValues = common.filterSubclasses(subClassValues, row);
        return {
            values: filteredSubClassValues.values
        }
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.classSubClass, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.classSubClass, params.value);
      },
    },
    
    {
      headerName: "Target COGS",
      field: "targetCogs",
      filter: true,
      cellEditor: cogsv,
      editable:  true,
      cellStyle: { overflow: "visible !important" },
    },
    
    {
      headerName: "Price Tier",
      field: "priceTier",
      editable: true,
      filter: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.priceTier)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.priceTier, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.priceTier, params.value);
      },
    },
    
    {
      headerName: "Type Of Request",
      field: "requestType",
      editable: true,
      filter: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.typeOfRequest)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.typeOfRequest, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.typeOfRequest, params.value);
      },
    },
    
    {
      headerName: "Pull Forward(Y/N)",
      field: "pullForward",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: { values: configData.datafields["pullForward"] },
    },
    
    {
      headerName: "Explanation of Request",
      field: "requestExplanation",
      editable: true,
      cellEditor: "agLargeTextCellEditor",
      filter: true
    },
    
    {
      headerName: "Anticipated Volume",
      field: "anticipatedVolume",
      editable: true,
      cellEditor: avv,
      cellStyle: function(params) {
        if(params.value >= 1500 && params.value <= 5000){
          return { overflow: "visible !important" , backgroundColor: "yellow"}
        } else {
          return { backgroundColor: ""}
        }
      },
      comparator: (a, b) => a - b
    },

    {
      headerName: "Last Season Offered",
      field: "lastSeasonOffered",
      filter: true,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.lastSeasonOffered)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.lastSeasonOffered, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.lastSeasonOffered, params.value);
      },
    },
    
    {
      headerName: "Product Description",
      field: "description",
      editable: true,
      cellEditor: "agLargeTextCellEditor",
      cellStyle: { overflow: "hidden" },
      cellClassRules: {
        required: row => row.node.isSelected() && !FIELD_VALIDATION_MAP.description(row.data),
      }
    },
    
    {
      headerName: "Fabric ",
      field: "fabric",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.fabric)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.fabric, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.fabric, params.value);
      },
    },

    {
      headerName: "Finish",
      field: "finish",
      editable: true
    },
    
    {
      headerName: "Proto Needs ",
      field: "protoNeeds",
      cellEditor: pnv,
      editable: true,
      cellStyle: { overflow: "visible !important" },
    },
    
    {
      headerName: "Sample Needs ",
      field: "sampleNeeds",
      editable: true,
      cellEditor: snv,
      cellStyle: { overflow: "visible !important" },
    },

    {
      headerName: "Requested On Floor",
      field: "requestOnFloor",
      editable: true,
      cellEditor: DatePicker.getDatePicker(vm.lookupData[configData.lookupTypes.schedules], configData),
      valueGetter: row => common.dateValueGetter(row.data.requestOnFloor),
    },
    
    {
      headerName: "Global Merchant",
      field: "globalMerchant",
      editable: true,
      cellClassRules: {
        required: row => row.node.isSelected() && !FIELD_VALIDATION_MAP.globalMerchant(row.data),
      }
    },
    
    {
      headerName: "PD Contact",
      field: "pdContact",
      editable: true,
      filter: true,
    },
    
    {
      headerName: "Actual PC9",
      field: "actualPC9",
      editable: true,
      filter: true,
      cellEditor: actualpc9v,
      cellStyle: { overflow: "visible !important" },
      cellClassRules: {
        required: row => row.node.isSelected() && !FIELD_VALIDATION_MAP.actualPC9(row.data),
      }
    },
    
    {
      headerName: "Fabric Code",
      field: "faCode",
      editable: true,
      cellEditor: fcv,
      cellStyle: { overflow: "visible !important" },
      cellClassRules: {
        required: row => row.node.isSelected() && !FIELD_VALIDATION_MAP.faCode(row.data),
      }
    },
    
    {
      headerName: "Fabric Description",
      field: "fabricDescription",
      editable: true
    },
    {
      headerName: "Product Line",
      field: "productLine",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: common.extractValues(vm.lookupData.productLine)
      },
      filterParams: {
        valueFormatter: (params) => {
          return common.lookupValue(vm.lookupData.productLine, params.value);
        },
      },
      valueFormatter: (params) => {
        return common.lookupValue(vm.lookupData.productLine, params.value);
      },
      cellStyle: { overflow: "visible !important" },
    },
    {
      headerName: "Color Look Code",
      field: "colorLookCode",
      editable: true,
      cellEditor: "agLargeTextCellEditor",
      cellStyle: { overflow: "visible !important" },
    },
    {
      headerName: "Finish Develoment Track",
      field: "finishDevelopmentTrack",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      suppressPaste: true,
      cellStyle: { overflow: "visible !important" },
      cellEditorParams: {
        values: configData.datafields.finishDevelopmentTrack
      },
    },
    {
      headerName: "Account Name",
      field: "accountName",
      editable: true,
      cellEditor: "agLargeTextCellEditor",
      cellStyle: { overflow: "visible !important" },
    },
    {
      headerName: "Exclusivity",
      field: "exclusivity",
      editable: row => row.data.accountName,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: configData.datafields["exclusivity"]
      },
      cellClassRules: {
        noneditableCol: row => !row.data.accountName
      },
    },
    {
      headerName: "Image Reference PC9",
      field: "imageReferencePC9",
      editable: true,
    },
    {
      headerName: "Gap Type",
      field: "gapType",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      suppressPaste: true,
      cellEditorParams: {
        values: configData.datafields["gapType"]
      },
      cellClassRules: {
        required: row => row.node.isSelected() && !FIELD_VALIDATION_MAP.gapType(row.data),
      }
    },

    {
      headerName: "Global Merch Comments",
      field: "gmiComments",
      editable: true,
      cellEditorParams: {
        maxLength: 1000,
    },
      cellEditor: "agLargeTextCellEditor"
    }
  ];
}

function updated() { }

async function save(vm) {
  vm.loading = true;
  vm.gridApi.stopEditing();
  vm.gridApi.refreshCells();
  const rows = vm.gridApi.getSelectedNodes();
  if (!rows.length) {
    vm.loading = false;
    swal.fire({
      title: "Please Select a Request",
      type: "error",
      confirmButtonText: "Ok"
    });
    return;
  }
  
  for (const row of rows) {
    const invalidFields = gmValidation(row.data);
    if (invalidFields.length) {
      vm.loading = false;
      swal.fire({
        type: "error",
        text: "Please fill the missing values/Invalid fields in the selected rows",
        confirmButtonText: "Ok"
      });
      return;
    }
  }

  // convert approval_state to action
  for (let row of rows) {
    const data = row.data;
    const approvalState = data.approval_state;
    if (approvalState) {
      data.action = approvalState.toUpperCase();
      if (approvalState === 'MORE_INFO') {
        data.moreInfoState = configData.states[data.toState];  // state number
        data.moreInfoRole = data.toState;  // role name
      }
    }
  }

  // building update and moreinfo product request promise
  const allRequests = rows.map(row => vm.gridApi.getRowNode(row.id).data);
  const requestsToUpdate = []
  const requestsToReturn = [];
  const requestsToActionize = [];

  // prompt for any return requests
  if (allRequests.some(row => row.lastAction === "MORE_INFO")) {
    const resp = await common.hasMoreInfoReq(vm);
    if (!resp.value || resp.dismiss) return
  }

  for (const row of allRequests) {
    if (row.lastAction === 'MORE_INFO') {
      requestsToReturn.push(row);
    } else {
      if (row.approval_state) {
        requestsToActionize.push({
          id: row.id,
          currentState: row.currentState,
          action: row.action,
          moreInfoComments: row.moreInfoComments,
          moreInfoRole: row.moreInfoRole,
          moreInfoState: row.moreInfoState,
          comments: row.gmiComments,
          gmiComments: row.gmiComments,
          requestReason: row.requestReason,
        });
      } else {
        requestsToUpdate.push(row);
      }
    }
  }
  
  try {
    const values = await Promise.all([
      common.createUpdateReturnRequest(requestsToUpdate, common.UPDATE_REQUEST, vm),
      common.createUpdateReturnRequest(requestsToReturn, common.RETURN_REQUEST, vm),
      common.createUpdateReturnRequest(requestsToActionize, common.ACTION_REQUEST, vm),
    ]);
    if (values && values.length === 3 && !values.includes(undefined) && !values.includes(NaN)) {
      const numUpdated = parseInt(values[0] || 0);
      const numReturned = parseInt(values[1] || 0);
      const numActionized = parseInt(values[2] || 0);
      const mes = [" Requests Updated : " + (numUpdated + numReturned + numActionized)];

      let alertType;
      let msg = [];
      if (numUpdated < requestsToUpdate.length)
        msg.push('Update');
      if (numReturned < requestsToReturn.length)
        msg.push('Return');
      if (numActionized < requestsToActionize.length)
        msg.push('PULL_BACK/MORE_INFO');
      
      if (msg.length > 0) {
        alertType = "warning";
        mes.push(`Some ${msg.join(', ')} requests may not have updated correctly`);
      } else {
        alertType = "success";
      }
      clickStream.obEvent('Submit', 'Submitted by Global Merch')
      swal.fire({
        text: mes,
        type: alertType,
        confirmButtonText: "Ok"
      });
    } else {
      swal.fire({
        text: "Failed! Error in creating/updating the request",
        type: "error",
        confirmButtonText: "Ok"
      });
    }
  } catch (e) {
    console.error(e);
    swal.fire({
      text: "Failed! Error in creating/updating the request",
      type: "error",
      confirmButtonText: "Ok"
    });
  }
  
  // eslint-disable-next-line
  vm.loading = false;
  await common.fetchGridContents(vm);
  vm.gridApi.deselectAll();
}

function drop(vm) {
  common.dropRequest(vm);
}

function gmValidation(data) {
  const fieldsFailing = Object.keys(FIELD_VALIDATION_MAP).filter(k => !FIELD_VALIDATION_MAP[k](data));
  console.debug(`Fields failing validation for product request ${data.id}:`, fieldsFailing);
  return fieldsFailing;
}

const FIELD_VALIDATION_MAP = {
  toState: data => data.approval_state !== 'MORE_INFO' || validations.generalTextValidation(data.toState),
  season: data => validations.generalTextValidation(data.season),
  globalMerchant: data => validations.generalTextValidation(data.globalMerchant),
  actualPC9: data => !common.isEmptyStr(data.approval_state) || validations.actualPC9(data.actualPC9),
  faCode: data => !common.isEmptyStr(data.approval_state) || data.requestType !== 'New' || validations.fabricCode(data.faCode),
  gapType: data => !common.isEmptyStr(data.approval_state) || data.requestType !== 'New' || validations.generalTextValidation(data.gapType),
  description: data => !common.isEmptyStr(data.approval_state) || data.requestType !== 'New' || validations.generalTextValidation(data.description),
}

export default {
  beforeMount,
  updated,
  save,
  drop,
  gmValidation,
  getColumnDefs,
}